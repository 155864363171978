import Component from '~/modules/Component'
import PreventFixedScroll from '~/modules/PreventFixedScroll'
import matchMedia from '~/helpers/matchMedia'
import { on } from '~/helpers/event'
import { dom, config } from '~/core'
import anime from 'animejs/lib/anime.es.js'

const NAME = 'nav'
const SELECTOR = {
  BUTTON: `.js-nav-button`
}
const CLASSES = {
  OPENED: `is-menu-opened`,
  LOCKED: 'is-locked'
}

export default class Navigation extends Component {
  constructor (element) {
    super(element, { name: NAME })

    this.isShown = false
    this.isAnimating = false
    this.preventFixed = new PreventFixedScroll(this.element)

    matchMedia(config.mediaQuery.sp, (matches) => {
      if (!matches && this.isShown) {
        this.reset()
      }
    })

    this.bindAll('onClick')
    this.bindEvents()
  }

  bindEvents () {
    this.delegateFn = on(
      dom.body,
      'click',
      this.onClick,
      false,
      SELECTOR.BUTTON
    )
  }

  unbindEvents () {
    this.delegateFn.destroy()
  }

  onClick (e) {
    return this[!this.isShown ? 'open' : 'close'](e)
  }

  async open () {
    if (this.isAnimating) {
      return
    }

    this.isAnimating = true
    this.preventFixed.freeze()

    dom.html.classList.add(...[CLASSES.OPENED, CLASSES.LOCKED])
    anime.set([this.element, ...this.dom.get('inner')], { opacity: 0 })

    const duration = 800
    const tl = anime.timeline()

    await tl
      .add({
        targets: this.element,
        opacity: 1,
        duration: duration,
        easing: 'easeOutCubic'
      })
      .add(
        {
          targets: this.dom.get('inner'),
          opacity: 1,
          duration: 800,
          easing: 'easeOutCubic'
        },
        `-=${duration}`
      ).finished

    this.preventFixed.refresh()
    this.isShown = !this.isShown
    this.isAnimating = !this.isAnimating
  }

  async close () {
    if (this.isAnimating) {
      return
    }

    this.isAnimating = true
    anime.set(this.element, { display: 'block' })
    dom.html.classList.remove(CLASSES.OPENED)
    const duration = 800
    const tl = anime.timeline()

    await tl
      .add({
        targets: this.dom.get('inner'),
        opacity: 0,
        duration: 800,
        easing: 'easeOutCubic'
      })
      .add(
        {
          targets: this.element,
          opacity: 0,
          duration: duration,
          easing: 'easeOutCubic'
        },
        `-=${duration}`
      ).finished
    anime.set(this.element, { display: '' })
    dom.html.classList.remove(CLASSES.LOCKED)

    this.preventFixed.destroy()
    this.reset()
  }

  reset () {
    anime.set([this.element, ...this.dom.get('inner')], { opacity: '' })
    dom.html.classList.remove(...[CLASSES.OPENED, CLASSES.LOCKED])
    this.preventFixed.destroy()
    this.isShown = false
    this.isAnimating = false
  }
}
