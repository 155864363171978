import Component from '~/modules/Component'
import Resizer from '~/modules/Resizer'
import matchMedia from '~/helpers/matchMedia'
import { on } from '~/helpers/event'
import { arrayTo2d } from '~/helpers/array'
import { getOffset } from '~/helpers/dom'
import { setStyle } from '~/helpers/style'
import { config } from '~/core'

const NAME = 'equal'

export default class EqualHeight extends Component {
  constructor (element) {
    super(element, { name: NAME })

    if (!this.element) {
      return
    }

    this.bindAll('onLoad', 'onResize')

    if (this.dom.has('item')) {
      this.setup()
    }

    matchMedia(config.mediaQuery.sp, (matches) => {
      if (!matches) {
        this.setup()
      }
    })

    on(window, 'load', this.onLoad)
    Resizer.add(this.onResize)
  }

  setup () {
    this.order()
    this.apply()
  }

  order () {
    this.dom.set(
      'item',
      this.dom.get('item').sort((a, b) => getOffset(a).top - getOffset(b).top)
    )
  }

  apply () {
    const cols = this.getCols()
    this.setRows(cols)
  }

  getCols () {
    return this.dom.get('item').reduce((acc, item, i) => {
      const top = getOffset(item).top
      const currentTop = acc.top || 0
      if (currentTop === 0 || currentTop === top) {
        acc.top = top
        acc.cols = i + 1
      }
      return acc
    }, {}).cols
  }

  setRows (cols) {
    const items = this.dom.get('item')
    setStyle(items, { height: 'auto' })
    const rows = arrayTo2d(items, cols)
    rows.forEach((row) => this.equalHeight(row))
  }

  equalHeight (row) {
    const max = Math.max.apply(null, row.map((item) => item.offsetHeight))
    setStyle(row, { height: `${max}px` })
  }

  onLoad () {
    this.setup()
  }

  onResize () {
    this.apply()
  }
}
