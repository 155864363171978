const observeMutations = (element, cb, options = {}) => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => cb(mutation))
  })

  observer.observe(element, {
    childList: true,
    attributes: true,
    attributeOldValue: true,
    characterData: true,
    characterDataOldValue: true,
    subtree: true,
    ...options
  })

  return observer
}

const observeIntersections = (element, cb, options = {}) => {
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => cb(entry, observer))
  })

  observer.observe(element, {
    root: null,
    ...options
  })

  return observer
}

export { observeMutations, observeIntersections }
