import Component from '~/modules/Component'
import inView from '~/helpers/inView'
import matchMedia from '~/helpers/matchMedia'
import { setStyle } from '~/helpers/style'
import { config } from '~/core'
import anime from 'animejs/lib/anime.es.js'

const NAME = 'sticky'
const CLASSES = {
  STICKY: 'is-sticky'
}

export default class StickyHeader extends Component {
  constructor (element) {
    super(element, { name: NAME })

    if (!this.element) {
      return
    }

    this.isShown = true
    this.isLoaded = false

    matchMedia(config.mediaQuery.sp, (matches) => {
      if (!matches) {
        this.isShown = true
        if (window.pageYOffset >= this.element.offsetHeight) {
          this.from()
        }
      } else {
        this.isShown = false
        this.reset()
      }
    })

    inView(this.element, this.from.bind(this), this.to.bind(this), {
      once: false
    })
  }

  reset () {
    this.dom.get('target')[0].classList.remove(CLASSES.STICKY)
  }

  from () {
    if (!this.isShown) {
      return
    }
    this.dom.get('target')[0].classList.add(CLASSES.STICKY)
    anime({
      targets: this.dom.get('target'),
      duration: 500,
      translateY: ['-100%', '0%'],
      easing: 'easeOutCubic',
      complete: () => {
        this.dom.get('target')[0].removeAttribute('style')
      }
    })
  }

  to () {
    if (!this.isShown) {
      return
    }

    if (!this.isLoaded) {
      this.isLoaded = true
      return
    }

    anime
      .timeline({
        targets: this.dom.get('target'),
        easing: 'easeOutCubic',
        duration: 200
      })
      .add({
        translateY: '-100%',
        complete: () => {
          setStyle(this.dom.get('target')[0], {
            zIndex: 2,
            position: 'relative'
          })
          this.reset()
        }
      })
      .add({
        translateY: '0%',
        duration: 500,
        complete: () => {
          this.dom.get('target')[0].removeAttribute('style')
        }
      })
  }
}
