import Top from '~/pages/Top'
import Lower from '~/pages/Lower'
import Form from '~/pages/Form'
import Entry from '~/pages/Entry'
import Recruit from '~/pages/Recruit'
import RecruitArticle from '~/pages/Recruit/Article'
import Tab from '~/ui/Tab'
import Blog from '~/ui/Blog'
import Modal from '~/ui/Modal'
import EqualHeight from '~/ui/EqualHeight'
import StickyHeader from '~/ui/StickyHeader'
import StickyNav from '~/ui/StickyNav'
import SmoothScroll from '~/ui/SmoothScroll'
import Navigation from '~/ui/Navigation'
import Accordion from '~/ui/Accordion'
import AccordionSP from '~/ui/AccordionSP'
import router from '~/modules/Router'

export default class App {
  constructor() {
    this.tab = new Tab('.js-tab')
    this.modal = new Modal()
    this.blog = new Blog('.js-blog')
    this.equal = new EqualHeight('.js-equal-height')
    this.navigation = new Navigation('.js-nav')
    this.smoothScroll = new SmoothScroll()
    this.stickyHeader = new StickyHeader('.js-sticky-header')
    this.stickyNav = new StickyNav('.js-sticky')
    this.accordion = [...document.querySelectorAll('.js-accordion')].map((el) => new Accordion(el))
    this.accordionSP = new AccordionSP('.js-accordion-sp')

    router.add(/(^\/|^\/index\.\html)$/, () => {
      new Top()
    })

    router.add(/^\/[a-zA-Z0-9.\-*.+]/, () => {
      new Lower()
    })

    router.add('(/form/|/form/index.html)$', () => {
      new Form()
    })

    router.add('(/recruit/entry/|/recruit/entry/index.html)$', () => {
      new Entry()
    })

    router.add('(/recruit/|/recruit/index.html)', () => {
      new Recruit()
    })

    router.add('(/recruit/article/|/recruit/article/index.html)$', () => {
      new RecruitArticle()
    })

    router.route()
  }
}
