import Component from '~/modules/Component'
import { imageLoader } from '~/helpers/loader'
import anime from 'animejs/lib/anime.es.js'

const NAME = 'hero'

export default class Hero extends Component {
  constructor (element) {
    super(element, {
      name: NAME
    })
  }

  load () {
    const imgs = [...this.element.querySelectorAll('img')]
    return Promise.all(imgs.map((img) => imageLoader))
  }

  async appear (duration = 1800) {
    const tl = anime.timeline({
      easing: 'easeOutCubic',
      duration
    })

    anime.set(
      [
        this.dom.get('gray')[0],
        this.dom.get('blue')[0],
        this.dom.get('pink')[0],
        this.dom.get('dot')[0],
        this.dom.get('content')[0]
      ],
      {
        visibility: 'visible'
      }
    )
    await tl
      .add({
        targets: this.dom.get('gray'),
        translateY: [-600, 0]
      })
      .add(
        {
          targets: this.dom.get('blue'),
          translateY: [500, 0]
        },
        `-=${duration}`
      )
      .add(
        {
          targets: this.dom.get('pink'),
          translateX: [-600, 0]
        },
        `-=${duration}`
      )
      .add(
        {
          targets: this.dom.get('dot'),
          translateX: [220, 0]
        },
        `-=${duration}`
      )
      .add(
        {
          targets: this.dom.get('content'),
          opacity: {
            value: [0, 1],
            easing: 'easeInOutCubic'
          },
          translateY: {
            value: [80, 0],
            duration: 2000,
            easing: 'easeOutCubic'
          }
        },
        `-=${duration}`
      ).finished
  }

  async animate () {
    anime.set([this.dom.get('heading')[0], this.dom.get('text')[0]], {
      visibility: 'visible'
    })
    await anime({
      targets: [this.dom.get('heading')[0], this.dom.get('text')[0]],
      opacity: [0, 1],
      duration: 1200,
      delay: anime.stagger(500),
      easing: 'easeInOutSine'
    }).finished
  }
}
