import Component from './Component'
import { detect } from '~/core'
import { on, off } from '~/helpers/event'

export default class PreventFixedScroll extends Component {
  constructor (element) {
    super(element)

    this.pageY = 0
    this.isFreeze = false
    this.captureOpts = detect.hasPassive
      ? {
        passive: false
      }
      : false

    this.bindAll('onTouchStart', 'onTouchMove', 'onOrientChange')
    this.setStyle()
    this.bindEvents()
  }

  setStyle () {
    const { style, offsetHeight, scrollHeight } = this.element

    if (offsetHeight === scrollHeight) {
      style.WebkitOverflowScrolling = ''
    } else {
      style.WebkitOverflowScrolling = 'touch'
    }
  }

  bindEvents () {
    on(this.element, 'touchstart', this.onTouchStart, this.captureOpts)
    on(this.element, 'touchmove', this.onTouchMove, this.captureOpts)
    on(window, 'orientationchange', this.onOrientChange, this.captureOpts)
  }

  unbindEvents () {
    off(this.element, 'touchstart', this.onTouchStart, this.captureOpts)
    off(this.element, 'touchmove', this.onTouchMove, this.captureOpts)
    off(window, 'orientationchange', this.onOrientChange, this.captureOpts)
  }

  onTouchStart (e) {
    if (this.isFreeze) {
      e.preventDefault()
    }

    if (e.targetTouches.length === 1) {
      this.pageY = e.changedTouches[0].pageY
    }
  }

  onTouchMove (e) {
    if (this.isFreeze) {
      e.preventDefault()
    }

    if (e.targetTouches.length === 1) {
      const { scrollTop, scrollHeight, offsetHeight } = this.element
      const pageY = e.changedTouches[0].pageY

      const isTop = this.pageY <= pageY && scrollTop === 0
      const isBottom =
        this.pageY >= pageY && scrollHeight - scrollTop === offsetHeight

      if (isTop || isBottom) {
        e.preventDefault()
      }
    }
  }

  onOrientChange () {
    this.refresh()
  }

  freeze () {
    this.isFreeze = true
    this.bindEvents()
  }

  refresh () {
    this.pageY = 0
    this.isFreeze = false
    this.element.scrollTop = 0
    this.setStyle()
  }

  destroy () {
    this.pageY = 0
    this.unbindEvents()
  }
}
