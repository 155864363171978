export default class Selectbox {
  constructor() {
    this.select = Array.from(document.querySelectorAll('select'))
    if (this.select) {
      this.select.forEach(elm => {
        elm.addEventListener('change', event => this.onChange(elm), false)
        elm.addEventListener('focus', event => elm.classList.remove('disabled'), false)
        this.onChange(elm)
      })
    }
  }

  async onChange(elm) {
    const classlist = elm.classList
    if (elm.value.length) {
      classlist.remove('disabled')
    } else {
      classlist.add('disabled')
    }
  }

}
