import { isFunction } from '~/helpers/type'

/**
 * Singleton
 */
class Router {
  constructor () {
    this.routeMap = new Map()
  }

  add (rule, fn) {
    if (!this.routeMap.has(rule)) {
      this.routeMap.set(rule, fn)
    }
  }

  remove (rule) {
    if (this.routeMap.has(rule)) {
      this.routeMap.delete(rule)
    }
  }

  route () {
    const { pathname } = location

    this.routeMap.forEach((fn, rule) => {
      if (pathname.match(rule) && isFunction(fn)) {
        fn()
      }
    })
  }
}

const router = new Router()
export default router
