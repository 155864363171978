import { createElement } from '~/helpers/dom'

/**
 * @example
 *  getPrefix(['transform', 'msTransform'])
 *
 * @param  {Array} props
 * @return {string}
 */
const getPrefix = (props = []) => {
  const div = createElement()
  return props.filter((prop) => {
    if (prop in div.style) {
      return prop
    }
    return false
  })[0]
}

export default getPrefix
