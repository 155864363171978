import '@babel/polyfill'
import 'whatwg-fetch'
import 'intersection-observer'
import doT from 'dot'
import App from '~/App'
import Footer from '~/ui/Footer'
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
}

// if ('scrollRestoration' in history) {
//   history.scrollRestoration = 'manual'
// }

doT.templateSettings = {
  evaluate: /<%([\s\S]+?(\}?)+)%>/g,
  interpolate: /<%=([\s\S]+?)%>/g,
  encode: /<%!([\s\S]+?)%>/g,
  use: /<%#([\s\S]+?)%>/g,
  useParams: /(^|[^\w$])def(?:\.|\[[\'\"])([\w$\.]+)(?:[\'\"]\])?\s*\:\s*([\w$\.]+|\"[^\"]+\"|\'[^\']+\'|\{[^\}]+\})/g,
  define: /<%##\s*([\w\.$]+)\s*(\:|=)([\s\S]+?)#%>/g,
  defineParams: /^\s*([\w$]+):([\s\S]+)/,
  conditional: /<%\?(\?)?\s*([\s\S]*?)\s*%>/g,
  iterate: /<%~\s*(?:%>|([\s\S]+?)\s*\:\s*([\w$]+)\s*(?:\:\s*([\w$]+))?\s*%>)/g,
  varname: 'it',
  strip: true,
  append: true,
  selfcontained: false,
  doNotSkipEncoded: false,
}

const footer = new Footer()
footer.loadHtml().then(() => {
  new App()
})
