import Component from '~/modules/Component'
import ImagesLoaded from '~/modules/ImagesLoaded'
import PreventFixedScroll from '~/modules/PreventFixedScroll'
import emitter from '~/modules/EventEmitter'
import getSelectorFromElement from '~/helpers/getSelectorFromElement'
import { loadIframeAPI, createPlayer } from '~/modules/ytPlayer'
import { on, off } from '~/helpers/event'
import { setStyle } from '~/helpers/style'
import { createElement, releaseNode, captureNode } from '~/helpers/dom'
import { dom, detect } from '~/core'
import anime from 'animejs/lib/anime.es.js'

const NAME = 'modal'
const SELECTOR = {
  YOUTUBE: '[data-youtube-id]'
}
const CLASSES = {
  LOADED: 'js-modal-loaded',
  OPENED: 'is-modal-opened',
  LOCKED: 'is-locked',
  MODAL: 'modal',
  CONTAINER: 'modal-container',
  CONTENTS: 'modal-contents',
  OVERLAY: 'js-modal-overlay',
  YOUTUBE_LOADING: 'is-youtube-loading'
}
const EVENTS = {
  SHOWN: `${NAME}:shown`,
  CLOSE: `${NAME}:close`,
  CHANGE: `${NAME}:change`,
  BEFORE_SHOWN: `${NAME}:before-shown`,
  BEFORE_CLOSE: `${NAME}:before-close`
}

export default class Modal extends Component {
  constructor ({ duration = 600, delay = 0, easing = 'easeOutCubic' } = {}) {
    super(dom.body, { name: NAME })

    this.options = {
      duration,
      delay,
      easing
    }
    this.isShown = false
    this.currentEl = null
    this.videoMap = new Map()

    if (!dom.html.classList.contains(CLASSES.LOADED)) {
      dom.html.classList.add(CLASSES.LOADED)
      this.build()
    }

    this.dom = this.getElements(this.element)
    this.preventFixedScroll = new PreventFixedScroll(this.dom.get('modal')[0])

    this.bindAll('onClick', 'onReady')
    this.bindEvents()

    if (this.dom.has('button-youtube')) {
      loadIframeAPI()
    }
  }

  build () {
    const modal = createElement('div', {
      className: [`js-${CLASSES.MODAL}`],
      attributes: {
        [`data-${this.dataAttr}`]: 'modal'
      }
    })
    const container = createElement('div', {
      className: CLASSES.CONTAINER,
      attributes: {
        [`data-${this.dataAttr}`]: 'container'
      }
    })
    const contents = createElement('div', {
      className: CLASSES.CONTENTS,
      attributes: {
        [`data-${this.dataAttr}`]: 'contents'
      }
    })
    const overlay = createElement('div', {
      className: CLASSES.OVERLAY,
      attributes: {
        [`data-${this.dataAttr}`]: 'overlay'
      }
    })

    container.appendChild(contents)
    modal.appendChild(container)
    ;[modal, overlay].forEach((element) => dom.body.appendChild(element))
  }

  bindEvents () {
    this.delegateFn = on(
      dom.body,
      'click',
      this.onClick,
      false,
      `[data-${this.dataAttr}^="button"]`
    )
  }

  unbindEvents () {
    off(dom.body, 'click', this.delegateFn)
  }

  onClick (e) {
    const target = e.delegateTarget
    const type = target
      .getAttribute(`data-${this.dataAttr}`)
      .replace(/(\w+)-(\w+)/, '$2')

    switch (type) {
      case 'modal':
      case 'youtube':
      case 'video':
      case 'close':
        return this[!this.isShown ? 'open' : 'close'](e, type)
    }
  }

  onReady (e) {
    dom.html.classList.remove(CLASSES.YOUTUBE_LOADING)
    const yt = e.target

    for (const video of this.videoMap.values()) {
      if (video === yt && this.isShown) {
        if (detect.isIOS) {
          video.mute()
        }
        video.playVideo()
      } else {
        video.stopVideo()
      }
    }
  }

  createYTPlayer () {
    dom.html.classList.add(CLASSES.YOUTUBE_LOADING)

    const youtubeEl = this.dom
      .get('contents')[0]
      .querySelector(SELECTOR.YOUTUBE)
    const youtubeId = youtubeEl.getAttribute(
      SELECTOR.YOUTUBE.replace(/\s|\[|\]/g, '')
    )

    if (!this.videoMap.has(youtubeId)) {
      this.videoMap.set(
        youtubeId,
        createPlayer(youtubeEl, youtubeId, {
          events: { onReady: this.onReady }
        })
      )
    }
  }

  toggleVideo (target) {
    for (const video of this.videoMap.values()) {
      if (video === target && this.isShown) {
        if (detect.isIOS) {
          video.muted = true
        }
        video.play()
      } else {
        video.pause()
      }
    }
  }

  isPlayingVideo () {
    const states = []

    if (this.videoMap.size === 0) {
      return false
    }

    for (const video of this.videoMap.values()) {
      states.push(video.paused)
    }

    return states.every((state) => state === false)
  }

  async open (e, type) {
    if (this.isShown) {
      return
    }

    const target = e.delegateTarget
    const modal = this.dom.get('modal')[0]
    const contents = this.dom.get('contents')[0]
    const overlay = this.dom.get('overlay')[0]

    if (target.tagName.toLowerCase() === 'a') {
      e.preventDefault()
    }

    setStyle(modal, { display: 'block' })
    setStyle([overlay, contents], {
      opacity: 0,
      display: 'block'
    })

    this.currentEl = getSelectorFromElement(target)
    emitter.emit(EVENTS.BEFORE_SHOWN, this.currentEl.dataset.id)

    releaseNode(contents)
    captureNode(this.currentEl, contents)

    if (type === 'youtube') {
      this.createYTPlayer()
    }

    this.isShown = !this.isShown

    if (type === 'video') {
      const id = this.currentEl.dataset.id
      const video = contents.querySelector('video')

      if (!this.videoMap.has(id)) {
        this.videoMap.set(id, video)
      }
      this.toggleVideo(video)
    }

    dom.html.classList.add(
      ...[CLASSES.OPENED, CLASSES.LOCKED, `${this.currentEl.dataset.id}-shown`]
    )

    this.showOverlay()
    this.showContent()
    this.preventFixedScroll.freeze()
    await ImagesLoaded.load(contents)
    this.preventFixedScroll.refresh()
    modal.scrollTop = 0
    emitter.emit(EVENTS.SHOWN, this.currentEl.dataset.id)
  }

  async close () {
    if (!this.isShown || !this.currentEl) {
      return
    }

    emitter.emit(EVENTS.BEFORE_CLOSE, this.currentEl.dataset.id)

    const modal = this.dom.get('modal')[0]
    const contents = this.dom.get('contents')[0]
    const overlay = this.dom.get('overlay')[0]

    await this.closeAnimation()

    emitter.emit(EVENTS.CLOSE, this.currentEl.dataset.id)
    dom.html.classList.remove(
      ...[CLASSES.OPENED, CLASSES.LOCKED, `${this.currentEl.dataset.id}-shown`]
    )
    this.isShown = !this.isShown

    if (this.isPlayingVideo()) {
      this.toggleVideo()
    }

    captureNode(contents, this.currentEl)

    setStyle(modal, { display: '' })
    setStyle(overlay, { display: 'none' })
    this.preventFixedScroll.destroy()
  }

  showOverlay () {
    const { easing, delay } = this.options
    return anime({
      targets: this.dom.get('overlay')[0],
      opacity: 1,
      duration: 400,
      delay,
      easing
    }).finished
  }

  showContent () {
    const { duration, easing, delay } = this.options
    return anime({
      targets: this.dom.get('contents')[0],
      opacity: 1,
      duration,
      delay,
      easing
    }).finished
  }

  closeAnimation () {
    const { duration, easing, delay } = this.options
    return anime({
      targets: [this.dom.get('contents')[0], this.dom.get('overlay')[0]],
      opacity: 0,
      duration,
      delay,
      easing
    }).finished
  }

  static get EVENTS () {
    return EVENTS
  }
}
