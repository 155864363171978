import Accordion from '~/ui/Accordion'
import matchMedia from '~/helpers/matchMedia'
import { config } from '~/core'

export default class AccordionSP {
  constructor (selector) {
    this.elements = [...document.querySelectorAll(selector)]
    this.accrodions = this.elements.map((el) => new Accordion(el))

    matchMedia(config.mediaQuery.sp, (matches) => {
      if (!matches) {
        this.reset()
      } else {
        this.active()
      }
    })
  }

  reset () {
    this.accrodions.forEach((accordion) => accordion.reset())
  }

  active () {
    this.accrodions.forEach((accordion) => accordion.activate())
  }
}
