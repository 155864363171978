/**
 * @see https://developers.google.com/youtube/iframe_api_reference?hl=ja
 */
const defaults = {
  width: 640,
  height: 360,
  playerVars: {
    autoplay: 0,
    controls: 2,
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
    wmode: 'opaque'
  },
  events: {
    onReady: null,
    onStateChange: null,
    onPlaybackQualityChange: null,
    onPlaybackRateChange: null,
    onError: null,
    onApiChange: null
  }
}

const loadIframeAPI = () => {
  return new Promise((resolve) => {
    if (window.YT) {
      resolve()
    } else {
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

      window.onYouTubeIframeAPIReady = () => {
        resolve()
      }
    }
  })
}

const createPlayer = (element, youtubeId, opts = {}) => {
  const options = {
    videoId: youtubeId,
    ...defaults,
    ...opts
  }

  return new YT.Player(element, options)
}

export { loadIframeAPI, createPlayer }
