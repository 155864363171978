const PI = Math.PI
const TAU = 2 * PI
const HALF_PI = PI / 2

/**
 * @param  {Array} arr
 */
const sum = (...arr) => [...arr].reduce((acc, val) => acc + val, 0)

/**
 * 平均を求める
 * @param  {Array} nums
 * @return {number}
 */
const average = (...nums) =>
  [...nums].reduce((acc, val) => acc + val, 0) / nums.length

/**
 * 距離を求める
 * @param  {number} x0
 * @param  {number} y0
 * @param  {number} x1
 * @param  {number} y1
 * @return {number}
 */
const distance = (x0, y0, x1, y1) => Math.hypot(x1 - x0, y1 - y0)

/**
 * 少数点n桁で丸める
 * @param  {number} n
 * @param  {number} decimals
 * @return {number}
 */
const round = (n, decimals = 0) =>
  Number(`${Math.round(`${n}e${decimals}`)}e-${decimals}`)

/**
 * 角度をラジアンに変換
 * @param  {number} deg
 * @return {number}
 */
const degreesToRads = (deg) => (deg * Math.PI) / 180

/**
 * ラジアンを角度に変換
 * @param  {number} rad
 * @return {number}
 */
const radsToDegrees = (rad) => (rad * 180.0) / Math.PI

const random = (min, max) => {
  if (!min) {
    return Math.random()
  }
  if (!max) {
    return Math.random() * min
  }
  return Math.random() * (max - min) + min
}

/**
 * 値の線形補間
 * @example lerp(0, 100, 0.5) -> 50
 * @param  {number} start
 * @param  {number} end
 * @param  {number} amount
 * @return {number}
 */
const lerp = (start, end, amount) => start * (1 - amount) + end * amount

/**
 * @param {number} val
 * @param {number} min
 * @param {number} max
 * @return {number}
 */
const clamp = (val, min, max) => Math.min(Math.max(min, val), max)

/**
 * 値の範囲変換
 * @see https://ikeryou.hatenablog.com/entry/2017/11/08/170133
 * @example map(50, -100, 100, 0, 1) -> 0.75
 * @param  {number} val
 * @param  {number} fromMin
 * @param  {number} fromMax
 * @param  {number} toMin
 * @param  {number} toMax
 * @return {number}
 */
const map = (val, fromMin, fromMax, toMin, toMax, withinBounds = true) => {
  const v = ((val - fromMin) / (fromMax - fromMin)) * (toMax - toMin) + toMin

  if (!withinBounds) {
    return v
  }

  if (toMin < toMax) {
    return clamp(v, toMin, toMax)
  } else {
    return clamp(v, toMax, toMin)
  }
}

/**
 * @param {number} index
 * @param {number} length
 * @return {number}
 */
const normalizeIndex = (index, length) => (length + (index % length)) % length

/**
 * @param {number} val
 * @param {number} digit
 * @param {string} method
 */
const toDecimal = (val, digit = 2, method = 'round') => {
  const multiplier = Math.pow(10, digit)
  return Math[method](val * multiplier) / multiplier
}

export {
  TAU,
  HALF_PI,
  sum,
  average,
  distance,
  round,
  degreesToRads,
  radsToDegrees,
  random,
  map,
  lerp,
  clamp,
  normalizeIndex,
  toDecimal
}
