export default class Footer {
  constructor() {
    this.footer = document.querySelector(`[data-elm='footer']`)
  }

  async loadHtml() {
    return new Promise((resolve, reject) => {
      fetch(`/assets/inc/inc_footer.html`)
        .then((response) => {
          return response.text()
        })
        .then((json) => {
          this.footer.innerHTML = json
          resolve()
        })
    })
  }
}
