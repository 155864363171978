/**
 * Singleton
 */
class EventEmitter {
  constructor () {
    this.eventLookup = {}
    // alias
    this.emit = this.fire
    this.trigger = this.fire
    this.addEventListener = this.on
    this.removeEventListener = this.off
  }

  /**
   * @param  {string}   eventName
   * @param  {Function} callback
   * @param  {Object}   scope
   * @param  {number}   priority
   * @return {Object}
   */
  once (eventName, callback, scope = this, priority = 0) {
    return this.on(eventName, callback, scope, priority, true)
  }

  /**
   * @param  {string}   eventName
   * @param  {Function} callback
   * @param  {Object}   scope
   * @param  {number}   priority
   * @param  {boolean}  once
   * @return {Object}
   */
  on (eventName, callback, scope = this, priority = 0, once = false) {
    let listeners = this.eventLookup[eventName]
    if (!listeners) {
      this.eventLookup[eventName] = listeners = []
    }
    let index = 0
    for (let i = 0, ii = listeners.length; i < ii; i++) {
      if (listeners[i].priority > priority) {
        index = i + 1
      }
    }
    listeners.splice(index, 0, {
      callback,
      scope,
      priority,
      once
    })
    return () => this.off(eventName, callback, scope)
  }

  /**
   * @param {string}   eventName
   * @param {Function} callback
   * @param {Object}   scope
   */
  off (eventName, callback, scope = this) {
    const listeners = this.eventLookup[eventName]
    if (eventName === '*') {
      this.eventLookup = {}
    } else if (!callback) {
      this.eventLookup[eventName] = []
    } else {
      const total = listeners.length
      for (let i = 0; i < total; i++) {
        if (
          listeners[i].callback === callback &&
          listeners[i].scope === scope
        ) {
          listeners.splice(i, 1)
          return
        }
      }
    }
  }

  /**
   * @param {string}  eventName
   * @param {...*} data
   */
  fire (eventName, ...data) {
    const listeners = this.eventLookup[eventName]

    if (!listeners) {
      return
    }

    for (let i = 0, ii = listeners.length; i < ii; i++) {
      const listener = listeners[i]
      if (listener.once) {
        this.off(eventName, listener.callback, listener.scope)
      }
      listener.callback.apply(listener.scope, data)
    }
  }
}

export default new EventEmitter()
