import Component from '~/modules/Component'
import Resizer from '~/modules/Resizer'
import Scroller from '~/modules/Scroller'
import matchMedia from '~/helpers/matchMedia'
import debounce from '~/helpers/debounce'
import anime from 'animejs/lib/anime.es.js'
import scrollTo from '~/vendors/animejs/scrollTo'
import { on, off, trigger } from '~/helpers/event'
import { dom, config } from '~/core'

const NAME = 'sticky'
const CLASSES = {
  STICKY: 'is-sticky',
  BOTTOM_STICKY: 'is-bottom-sticky',
  OPENED: 'is-recruit-menu-opened'
}

export default class StickyNav extends Component {
  constructor (element) {
    super(element, { name: NAME })

    if (!this.element) {
      return
    }

    this.isShown = false
    this.isAnimating = false
    this.isBottomSticky = false
    this.bounds = {}

    this.bindAll('onClick', 'onAnchorClick', 'onScroll', 'onResize')
    this.bindEvents()

    Resizer.add(debounce(this.onResize, 100))
    Scroller.add(this.onScroll)

    matchMedia(config.mediaQuery.sp, (matches) => {
      if (!matches && this.isShown) {
        this.reset()
      }
      trigger(window, 'resize')
    })
  }

  bindEvents () {
    if (this.dom.has('button')) {
      on(this.dom.get('button')[0], 'click', this.onClick)
    }
    this.delegateFn = on(this.element, 'click', this.onAnchorClick, false, 'a')
  }

  unbindEvents () {
    if (this.dom.has('button')) {
      off(this.dom.get('button')[0], 'click', this.onClick)
    }
    this.delegateFn.destory()
  }

  onClick (e) {
    return this[!this.isShown ? 'open' : 'close'](e)
  }

  async onAnchorClick (e) {
    const href = e.delegateTarget.getAttribute('href')
    const selector = href.match(/(?:\s|^)?#[A-Za-z0-9\-\.\_]+(?:\s|$)/g)
    const target = document.querySelector(selector)

    if (target) {
      e.preventDefault()

      if (!this.isAnimating) {
        if (this.isShown) {
          this.close()
        }
        scrollTo(target, { duration: 800, easing: 'easeOutQuint' })
      }
    }
  }

  onScroll (pageYOffset) {
    const bottom = this.bounds.bottom || 0
    const height = this.dom.get('target')[0].offsetHeight

    if (this.isBottomSticky && pageYOffset >= bottom - height) {
      const slideHeight = bottom - this.bounds.top || 0
      if (slideHeight < height) {
        return
      }
      this.addBottomSticky()
    } else if (pageYOffset >= this.bounds.top) {
      this.addSticky()
    } else if (pageYOffset <= bottom) {
      this.removeSticky()
    }
  }

  onResize () {
    const bounds = this.element.getBoundingClientRect()

    if (Object.keys(this.bounds).length === 0) {
      this.bounds = {
        top: 0,
        bottom: 0
      }
    }

    const top = bounds.top + window.pageYOffset
    const bottom = top + bounds.height

    this.bounds.top = top
    this.bounds.bottom = bottom

    if (bottom > top) {
      this.isBottomSticky = true
    } else {
      this.isBottomSticky = false
    }
    trigger(window, 'scroll')
  }

  async open () {
    if (this.isAnimating) {
      return
    }

    this.isAnimating = true

    dom.html.classList.add(CLASSES.OPENED)

    const nav = this.dom.get('nav')[0]
    const overlay = this.dom.get('overlay')[0]

    anime.set([nav, overlay], {
      display: 'block',
      opacity: 0
    })

    const navHeight = nav.offsetHeight
    const tl = anime.timeline()
    const duration = 800

    anime.set(nav, {
      height: 0,
      opacity: 1
    })

    await tl
      .add({
        targets: overlay,
        opacity: 1,
        duration: duration,
        easing: 'easeOutCubic'
      })
      .add(
        {
          targets: nav,
          height: navHeight,
          duration: duration / 2,
          easing: 'easeOutCubic'
        },
        `-=${duration}`
      ).finished

    anime.set(nav, { height: 'auto' })

    this.isShown = !this.isShown
    this.isAnimating = !this.isAnimating
  }

  async close () {
    if (this.isAnimating) {
      return
    }

    this.isAnimating = true

    const nav = this.dom.get('nav')[0]
    const overlay = this.dom.get('overlay')[0]
    const tl = anime.timeline()
    const duration = 800

    await tl
      .add({
        targets: overlay,
        opacity: 0,
        duration: duration,
        easing: 'easeOutCubic'
      })
      .add(
        {
          targets: nav,
          height: 0,
          duration: duration / 2,
          easing: 'easeOutCubic'
        },
        `-=${duration}`
      ).finished

    dom.html.classList.remove(CLASSES.OPENED)
    ;[nav, overlay].forEach((el) => el.removeAttribute('style'))

    this.isShown = false
    this.isAnimating = false
  }

  addSticky () {
    this.removeBottomSticky()
    this.dom.get('target')[0].classList.add(CLASSES.STICKY)
  }

  removeSticky () {
    this.dom.get('target')[0].classList.remove(CLASSES.STICKY)
  }

  addBottomSticky () {
    this.removeSticky()
    this.dom.get('target')[0].classList.add(CLASSES.BOTTOM_STICKY)
  }

  removeBottomSticky () {
    this.dom.get('target')[0].classList.remove(CLASSES.BOTTOM_STICKY)
  }

  reset () {
    const nav = this.dom.get('nav')[0]
    const overlay = this.dom.get('overlay')[0]

    dom.html.classList.remove(CLASSES.OPENED)
    ;[nav, overlay].forEach((el) => el.removeAttribute('style'))

    this.isShown = false
    this.isAnimating = false
  }
}
