/**
 * @param  {number} ms
 * @return {Promise}
 */
const sleep = (ms) => {
  return new Promise((resolve) => {
    const id = setTimeout(() => resolve(id), ms)
  })
}

export default sleep
