import doT from 'dot'
import { dom } from '~/core'
import { trigger } from '~/helpers/event'

const SELECT_CLASS = 'is-selected'

export default class Recruit {
  constructor() {
    if (dom.body.classList.contains('page-recruit-top')) {
      this.newsTab = document.querySelector('.js-news-tab')
      this.newsButton = Array.from(this.newsTab.querySelectorAll('li'))
      this.listSelector = document.querySelector('.js-news-list')
      this.tempSelector = document.querySelector('.js-news-template')
      this.template = doT.template(this.tempSelector.innerHTML)
      this.loadJson()
    }
  }

  async loadJson() {
    fetch('/cms/include.php')
      .then(response => response.json())
      .then(json => this.firstCreate(json))
      .then(() => {
        this.newsButton.forEach(_elm => {
          _elm.addEventListener('click', event => this.onClickChange(event), false)
        })
      })
  }

  async firstCreate(json) {
    this.json = json
    this.create('all')
  }

  async create(area) {
    const createArray = {
      entries: this.json.filter(item => item.area === area || item.area === 'all' || area === 'all').slice(0, 10)
    }
    this.listSelector.innerHTML = this.template(createArray)
    trigger(window, 'resize')
  }

  async onClickChange(event) {
    event.preventDefault()
    const target = event.currentTarget
    const hash = target.querySelector('a').hash.replace('#', '')
    this.newsButton.forEach(elm => {
      const elmClass = elm.classList
      if (elm === target) {
        elmClass.add(SELECT_CLASS)
      } else {
        elmClass.remove(SELECT_CLASS)
      }
    })
    this.create(hash)
  }
}
