const fetcher = (type = 'text', { credentials = 'same-origin' } = {}) => {
  const options = {
    credentials
  }
  return {
    get: async (url) => {
      return fetch(url, options)
        .then((res) => {
          if (!res.ok) {
            throw Error(res.statusText)
          }
          return res[type]()
        })
        .catch((err) => {
          return new Error(err.message)
        })
    }
  }
}

export default fetcher
