import { toArray } from './dom'

/**
 * @param  {HTMLElement} element
 * @param  {string}      prop
 * @return {string}
 */
const getStyle = (element, prop) => getComputedStyle(element)[prop]

const getMatrix = (element) => {
  const transform = getStyle(element, 'transform')
  const rePattern = /^matrix\((.+)\)$/
  const matrix = {
    x: 0,
    y: 0,
    scaleX: 0,
    scaleY: 0,
    skewX: 0,
    skewY: 0
  }

  if (transform.match(rePattern)) {
    const val = transform.match(rePattern)[1].split(',')
    matrix.x = parseFloat(val[4])
    matrix.y = parseFloat(val[5])
    matrix.scaleX = parseFloat(val[0])
    matrix.scaleY = parseFloat(val[3])
    matrix.skewX = parseFloat(val[2])
    matrix.skewY = parseFloat(val[1])
  }

  return matrix
}

/**
 * @param {HTMLElement|HTMLCollection|NodeList} elements
 * @param {Object}                              props
 */
const setStyle = (elements, props) => {
  toArray(elements).forEach((el) => {
    const style = el.style
    for (const [key, val] of Object.entries(props)) {
      style[key] = val
    }
  })
}

export { getStyle, getMatrix, setStyle }
