import Validate from '~/ui/Validate'
import Selectbox from '~/ui/Selectbox'

const school = {
  東京地区: [
    '中野区認可保育園　なかよしの森保育園',
    '練馬区認可保育園　あかねの森保育園',
    '国分寺市認可保育園　ともだちの森保育園',
    '国分寺市認可保育園　えがおの森保育園',
    '国分寺市認可保育園　こもれびの森保育園',
    '国分寺市認可保育園　ひだまりの森保育園',
    '国分寺市認可保育園　あそびの森保育園',
    '国分寺市認可保育園　あしたの森保育園',
    '小平市認可保育園　たのしい森保育園',
    '小平市認可保育園　やさしい森保育園',
    '小平市認可保育園　うれしい森保育園',
    '小平市認可保育園　ふれあいの森保育園',
    '小平市認可保育園　しあわせの森保育園',
    '小平市認可保育園　すこやかな森保育園',
    '立川市認可保育園　ほほえみの森保育園',
    '立川市認可保育園　きらめきの森保育園',
    '調布市認可保育園　しきの森保育園',
    '狛江市認可保育園　いずみの森保育園',
    '（予定）小金井市認可保育園　（仮称）げんきな森保育園',
  ],
  大分地区: [
    '幼保連携型認定こども園　よいこの森こども園本園',
    '幼保連携型認定こども園　よいこの森こども園分園',
    '大分市認可保育園　かがやきの森保育園',
    '大分市認可保育園　こころの森保育園',
  ],
  愛知地区: ['（予定）名古屋市認可保育園　（仮称）さつきの森保育園'],
  兵庫地区: ['明石市認可保育園　あかつきの森保育園'],
  福岡地区: [
    '幼保連携型認定こども園　ゆめの森こども園',
    '福岡市認可保育園　きぼうの森保育園',
    '福岡市認可保育園　みらいの森保育園',
    '福岡市認可保育園　ひかりの森保育園',
  ],
}

export default class Entry {
  constructor() {
    this.area = document.querySelector('.js-linkage-area')
    this.school = document.querySelector('.js-linkage-school')
    this.area.addEventListener('change', (event) => this.onChangeSelect(event), false)
    this.onChangeSelect()
    new Validate()
    new Selectbox()
  }

  async onChangeSelect(event) {
    const schoolList = school[this.area.value]
    let html =
      '<option value="" disabled selected style="display: none;">施設を選択してください</option><optgroup label="">'
    if (schoolList) {
      schoolList.forEach((elm) => {
        html += `<option value="${elm}">${elm}</option>`
      })
    }
    html += `</optgroup>`
    this.school.classList.add('disabled')
    this.school.innerHTML = html
  }
}
