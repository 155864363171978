import { isArray, isNodeList, isHTMLCollection } from './type'

/**
 * @param  {HTMLElement|NodeList} elements
 * @param  {HTMLElement}          target
 * @return {number}
 */
const getIndex = (elements, target) => {
  return toArray(elements).indexOf(target)
}

/**
 * @param {HTMLElement} element
 * @return {Object}
 */
const getOffset = (element) => {
  let x = 0
  let y = 0

  while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
    x +=
      element.offsetLeft - (element.tagName !== 'BODY' ? element.scrollLeft : 0)
    y +=
      element.offsetTop - (element.tagName !== 'BODY' ? element.scrollTop : 0)
    element = element.offsetParent
  }

  return {
    top: y,
    left: x
  }
}

/**
 * @param  {string} htmlString
 * @return {HTMLCollection}
 */
const parse = (htmlString) => {
  const div = createElement()
  div.innerHTML = htmlString
  return div.children
}

/**
 * @param  {HTMLElement}  element
 * @param  {Function} filter
 * @return {Array}
 */
const siblings = (element, filter) => {
  const siblings = []
  element = element.parentNode.firstChild
  do {
    if (!filter || filter(element)) {
      siblings.push(element)
    }
  } while ((element = element.nextSibling))
  return siblings
}

/**
 * @param  {HTMLElement}  element
 * @param  {string}       selector
 * @return {?HTMLElement}
 */
const closest = (element, selector) => {
  while (element) {
    const parent = element.parentElement
    if (parent && parent.matches && parent.matches(selector)) {
      return parent
    }
    element = parent
  }
  return null
}

/**
 * @param {HTMLElement} element
 */
const remove = (element) => {
  element.parentNode !== null && element.parentNode.removeChild(element)
}

/**
 * @param {HTMLElement} element
 */
const removeChildren = (element) => {
  while (element.firstChild) {
    element.removeChild(element.firstChild)
  }
}

/**
 * @param  {HTMLElement|NodeList|HTMLCollection} val
 * @return {Array}
 */
const toArray = (val) => {
  if (isArray(val)) return val
  if (isNodeList(val) || isHTMLCollection(val)) return Array.from(val)
  return [val]
}

/**
 * @example
 *  insertBefore(document.getElementById('myId'), '<p>before</p>'); // <p>before</p> <div id="myId">...</div>
 * @param {HTMLElement} element
 * @param {string}      htmlString
 */
const insertBefore = (element, htmlString) => {
  element.insertAdjacentHTML('beforebegin', htmlString)
}

/**
 * @example
 *  insertAfter(document.getElementById('myId'), '<p>after</p>'); // <div id="myId">...</div> <p>after</p>
 * @param {HTMLElement} element
 * @param {string}      htmlString
 */
const insertAfter = (element, htmlString) => {
  element.insertAdjacentHTML('afterend', htmlString)
}

/**
 * @param {HTMLElement} element
 * @param {HTMLElement} target
 */
const captureNode = (element, target) => {
  while (element.childNodes.length > 0) {
    target.appendChild(element.childNodes[0])
  }
}

/**
 * @param {HTMLElement} element
 */
const releaseNode = (element) => {
  while (element.firstChild) {
    element.removeChild(element.firstChild)
  }
}

/**
 * @param  {string}       tagName
 * @param  {Object}       args
 * @param  {string}       args.id
 * @param  {string|Array} args.className
 * @param  {string}       args.cssText
 * @param  {string}       args.innerHtml
 * @param  {Object}       args.attributes
 * @return {HTMLElement}  element
 */
const createElement = (
  tagName = 'div',
  { id, className, cssText, innerHTML, attributes } = {}
) => {
  const element = document.createElement(tagName)

  if (id) {
    element.id = id
  }

  if (className) {
    isArray(className)
      ? element.classList.add(...className)
      : element.classList.add(className)
  }

  if (cssText) {
    element.style.cssText = cssText
  }

  if (innerHTML) {
    element.innerHTML = innerHTML
  }

  if (attributes) {
    for (const [key, val] of Object.entries(attributes)) {
      element.setAttribute(key, val)
    }
  }

  return element
}

export {
  getIndex,
  getOffset,
  parse,
  siblings,
  closest,
  remove,
  removeChildren,
  toArray,
  insertBefore,
  insertAfter,
  captureNode,
  releaseNode,
  createElement
}
