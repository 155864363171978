import StickyNav from '~/ui/StickyNav'
import doT from 'dot/doT'

const areaName = {
  tokyo: '東京',
  fukuoka: '福岡',
  oita: '大分',
  all: '東京・福岡・大分'
}

export default class RecruitArticle {
  constructor() {
    this.sticky = new StickyNav('.js-sticky')
    this.id = this.parameter().id
    this.articleSelector = document.querySelector('.js-news-article')
    this.tempSelector = document.querySelector('.js-news-article-template')
    this.template = doT.template(this.tempSelector.innerHTML)
    this.loadJson()
  }

  async loadJson() {
    fetch(`/cms/include.php?id=${this.id}`)
      .then(response => response.json())
      .then(json => this.create(json))
  }

  async create(json) {
    this.json = json[0]
    const area = areaName[this.json.area]
    const createArray = {
      entries: [
        {
          title: this.json.title,
          date: this.json.date,
          text: this.json.text,
          area: area
        }
      ]
    }

    this.articleSelector.innerHTML = this.template(createArray)
  }

  parameter() {
    let v = []
    const url = location.href.replace(/#.*$/, '')
    const hashes = url.slice(window.location.href.indexOf('?') + 1).split('&')
    for (let hash of hashes) {
      const h = hash.split('=')
      v.push(h[0])
      v[h[0]] = h[1]
    }
    return v
  }
}
