import { observeIntersections } from '~/helpers/observer'
import { isString } from '~/helpers/type'

const inView = (
  element,
  from,
  to,
  { rootMargin = '0% 50%', threshold = 1, once = true } = {}
) => {
  element = isString(element) ? document.querySelector(element) : element

  const observer = observeIntersections(
    element,
    (entry) => {
      if (Array.isArray(threshold)) {
        to(entry)
      } else if (entry.isIntersecting) {
        to(entry)

        if (once) {
          observer.unobserve(entry.target)
        }
      } else if (!once) {
        from(entry)
      }
    },
    {
      rootMargin,
      threshold
    }
  )
}

export default inView
