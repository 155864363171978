import Component from '~/modules/Component'
import Resizer from '~/modules/Resizer'
import Scroller from '~/modules/Scroller'
import getSelectorFromElement from '~/helpers/getSelectorFromElement'
import debounce from '~/helpers/debounce'
import { on, off, trigger } from '~/helpers/event'

const NAME = 'spy'
const CLASSES = {
  LOCATED: 'located'
}

export default class SpyNav extends Component {
  constructor (element) {
    super(element, { name: NAME })

    this.bounds = {}
    this.current = null
    this.pageYOffset = 0
    this.direction = 'down'
    this.bindAll('onClick', 'onScroll', 'onResize')

    if (this.dom.has('link')) {
      this.dom.set('target', this.getTargetElement())
      Resizer.add(debounce(this.onResize, 100))
      Scroller.add(this.onScroll)
      ;['resize', 'scroll'].forEach((event) => trigger(window, event))

      this.bindEvents()
    }
  }

  getTargetElement () {
    return this.dom.get('link').map((el) => getSelectorFromElement(el))
  }

  updateClasses (current) {
    ;[...this.dom.get('home'), ...this.dom.get('link')].forEach((el) => {
      const list = el.parentNode

      if (list.classList.contains(CLASSES.LOCATED)) {
        list.classList.remove(CLASSES.LOCATED)
      } else if (el.getAttribute('href').substring(1) === current) {
        list.classList.add(CLASSES.LOCATED)
      }
    })
    this.current = current
  }

  bindEvents () {
    this.delegateFn = on(document, 'click', this.onClick, false, this.selector)
  }

  unbindEvents () {
    off(document, 'click', this.delegateFn)
  }

  onClick (e) {
    const target = e.delegateTarget

    if (target.tagName.toLowerCase() === 'a') {
      e.preventDefault()
    }
  }

  onScroll (pageYOffset) {
    Object.keys(this.bounds).forEach((key) => {
      if (
        this.bounds[key].top <= pageYOffset &&
        this.bounds[key].bottom >= pageYOffset
      ) {
        if (this.current !== key) {
          this.updateClasses(key)
        }
      }
    })
  }

  onResize () {
    this.dom.get('target').forEach((el) => {
      const id = el.id
      const bounds = el.getBoundingClientRect()

      if (!Object.keys(this.bounds).includes(id)) {
        this.bounds[id] = {
          top: 0,
          bottom: 0
        }
      }

      const top = bounds.top + window.pageYOffset
      const bottom = top + bounds.height

      this.bounds[id].top = Math.floor(top)
      this.bounds[id].bottom = Math.floor(bottom)
    })

    if (this.dom.has('home')) {
      const home = this.dom.get('home')[0]
      const id = home.getAttribute('href').substring(1)
      const minOffsetTop = Object.keys(this.bounds).reduce((acc, key) => {
        const { top } = this.bounds[key]
        if (key === 'top') {
          return acc
        }
        if (acc === 0 || acc > top) {
          acc = top
        }
        return acc
      }, 0)

      if (!Object.keys(this.bounds).includes(id)) {
        this.bounds[id] = {
          top: 0,
          bottom: minOffsetTop - 1
        }
      } else {
        this.bounds[id].top = 0
        this.bounds[id].bottom = minOffsetTop - 1
      }
    }

    trigger(window, 'scroll')
  }
}
