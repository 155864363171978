/**
 * @param {Function} func
 * @param {number}   waitMs
 * @param {boolean}  immediate
 */
const debounce = (func, waitMs = 250, immediate = false) => {
  let timeout
  return (...args) => {
    const fn = func.bind(this)
    const later = () => {
      if (!immediate) {
        fn(...args)
      }
      timeout = null
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, waitMs)
    if (callNow) {
      fn(...args)
    }
  }
}

export default debounce
